import React from 'react';
import { TypographyMuted } from '@/components/ui/typography';
import { TKDataHandlerImageProps } from './TKDataHandler';

const TKNoDataFound: React.FC<TKDataHandlerImageProps> = ({
  children,
}) => {
  return (
    <div
      className="flex flex-col justify-center items-center h-full w-full"
      data-testid="no-data-found"
    >
        <TypographyMuted className="text-center">{children}</TypographyMuted>
    </div>
  );
};

export default TKNoDataFound;
