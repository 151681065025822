import React, { useMemo } from 'react';
import Navigation from './DashboardLayout/Navigation';
import Routes from '@/routes';
import { useRouter } from '@/hooks/common/useRouter';
import { useAuth } from '@/contexts/Global/AuthContext';
import { trpc } from '@/api/trpc';

const AppLayout: React.FC = () => {
  const { pathname } = useRouter();
  const auth = useAuth();
  const healthz = trpc.healthz.check.useQuery();

  const showNavigation = useMemo(() => {
    return (
      !auth.isLoading &&
      healthz.data?.status === 'OK' &&
      !pathname.includes('/auth')
    );
  }, [auth.isLoading, healthz.data?.status, pathname]);

  const hideNavigationLinks = useMemo(() => !auth.companyId, [auth.companyId]);

  return (
    <div className="h-full w-full flex flex-col-reverse sm:flex-row justify-between sm:justify-start overflow-hidden">
      {showNavigation ? <Navigation hideMenu={hideNavigationLinks} /> : null}
      <div className="flex-1 relative overflow-hidden">
        <Routes />
      </div>
    </div>
  );
};

export default AppLayout;
