// This eslint rule exists only to prevent developers from accidentally importing server code into clients
// WARNING! Must only import the type definition from the server
// eslint-disable-next-line no-restricted-imports
import type { AppRouter } from '@tkd/trpc/src/routers/router';
import {
  createTRPCReact,
  TRPCClientErrorLike as defaultTRPCClientErrorLike,
  type inferReactQueryProcedureOptions,
} from '@trpc/react-query';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';

export const trpc = createTRPCReact<AppRouter>({
  overrides: {
    useMutation: {
      /**
       * This function is called whenever a `.useMutation` succeeds
       **/
      async onSuccess(opts) {
        /**
         * @note that order here matters:
         * The order here allows route changes in `onSuccess` without
         * having a flash of content change whilst redirecting.
         **/
        // Calls the `onSuccess` defined in the `useQuery()`-options:
        await opts.originalFn();
        // Invalidate all queries in the react-query cache:
        await opts.queryClient.invalidateQueries();
      },
    },
  },
});
export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>;
export type RouterInputs = inferRouterInputs<AppRouter>;
export type RouterOutputs = inferRouterOutputs<AppRouter>;
export type { AppRouter };
export type TRPCClientErrorLike = defaultTRPCClientErrorLike<AppRouter>;
