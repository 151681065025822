import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { Translations } from '@/i18n-resources';
import workspaceTranslationsEN from '@tkd/translations/files/web/en-GB/translation.json';
import workspaceTranslationsNO from '@tkd/translations/files/web/nb-NO/translation.json';
import sharedTranslationsEN from '@tkd/translations/files/shared/en-GB/translation.json';
import sharedTranslationsNO from '@tkd/translations/files/shared/nb-NO/translation.json';

export type AlphaCountryCodes = keyof Translations['country']['nationalities'];

type Language = {
  region: AlphaCountryCodes;
  fullName: string;
  code: string;
};

/** List of available languages. */
export const languages: Language[] = [
  {
    fullName: 'English',
    region: 'GB',
    code: 'en',
  },
  {
    fullName: 'Norsk bokmål',
    region: 'NO',
    code: 'no',
  },
];
export const fallbackLng = 'en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: { ...workspaceTranslationsEN, ...sharedTranslationsEN },
      },
      no: {
        translation: { ...workspaceTranslationsNO, ...sharedTranslationsNO },
      },
    },
    supportedLngs: ['en', 'no'],
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'navigator'],
      convertDetectedLanguage: (lng: string) => {
        if (lng === 'nn-NO') return 'no';
        if (lng === 'nb-NO') return 'no';
        return lng;
      },
    },
    debug: false,
    returnNull: false,
    ns: ['translation'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
