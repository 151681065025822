/* eslint-disable no-restricted-imports */
import { useCallback, useMemo } from 'react';
import {
  format,
  formatDistance,
  formatDuration,
  formatRelative,
  formatDistanceToNow,
  formatDistanceStrict,
  formatDistanceToNowStrict,
  parse,
  Day,
  differenceInMilliseconds,
  addMinutes,
} from 'date-fns';
import { enGB } from 'date-fns/locale/en-GB';
import { nb } from 'date-fns/locale/nb';
import { useTranslation } from 'react-i18next';
import { getMaxDateAsISOString } from '@/utils/common/dateFnsUtils';
import padStart from 'lodash/padStart';

/** List of Locales */
const locales = {
  en: enGB,
  no: nb,
};

type FormatDurationOptions = NonNullable<Parameters<typeof formatDuration>[1]>;
interface FormatDistanceCustomOptions extends FormatDurationOptions {
  onlyNumeric?: boolean;
  addSuffix?: boolean;
}

/** Time format used in postgres */
const postgresTimeFormat = 'HH:mm:ss+00';
const postgresTimeFormatNoTimezone = 'HH:mm:ss';

/**
 * Wrapper on top of date-fns that provides some helper functions and localized wrapper functions for formatting functions found in date-fns.
 *
 * @return {*} Mamoized list of all the wrappers and utilities for `date-fns`.
 */
const useDateFns = () => {
  const { i18n, t } = useTranslation();

  /** Memoize current date-fns locale. */
  const locale = useMemo(
    () => locales[i18n.language as keyof typeof locales] ?? enGB,
    [i18n.language]
  );

  /** Wrapper to inject the current user language into `format` function.  */
  const formatWithLocale: typeof format = useCallback(
    (date, formatStr, options) => {
      return format(date, formatStr, { ...options, locale });
    },
    [locale]
  );

  /** Wrapper to inject the current user language into `formatDuration` function.  */
  const formatDurationWithLocale: typeof formatDuration = useCallback(
    (duration, options) => {
      return formatDuration(duration, { ...options, locale });
    },
    [locale]
  );

  /** Wrapper to inject the current user language into `formatDistance` function.  */
  const formatDistanceWithLocale = useCallback(
    (date: Date, baseDate: Date, options?: FormatDistanceCustomOptions) => {
      return formatDistance(date, baseDate, {
        ...options,
        locale,
        onlyNumeric: options?.onlyNumeric,
      } as FormatDistanceCustomOptions);
    },
    [locale]
  );

  /** Wrapper to inject the current user language into `formatDistanceStrict` function.  */
  const formatDistanceStrictWithLocale: typeof formatDistanceStrict =
    useCallback(
      (date, baseDate, options) => {
        return formatDistanceStrict(date, baseDate, { ...options, locale });
      },
      [locale]
    );

  /** Wrapper to inject the current user language into `formatDistanceToNow` function.  */
  const formatDistanceToNowWithLocale: typeof formatDistanceToNow = useCallback(
    (date, options) => {
      return formatDistanceToNow(date, { ...options, locale });
    },
    [locale]
  );

  /** Wrapper to inject the current user language into `formatDistanceToNowStrict` function.  */
  const formatDistanceToNowStrictWithLocale: typeof formatDistanceToNowStrict =
    useCallback(
      (date, options) => {
        return formatDistanceToNowStrict(date, { ...options, locale });
      },
      [locale]
    );

  /** Wrapper to inject the current user language into `formatRelative` function.  */
  const formatRelativeWithLocale: typeof formatRelative = useCallback(
    (date, baseDate, options) => {
      return formatRelative(date, baseDate, { ...options, locale });
    },
    [locale]
  );

  /** Array of localized weekdays names. */
  const weekdays: string[] = Array.from({ length: 7 }).map((_, i) =>
    locale.localize?.day(i as Day)
  );
  // Dinamically adjust day-name translations based on when the week starts.
  if (locale.options?.weekStartsOn === 1) {
    weekdays.push(weekdays.shift()!);
  }
  /** Array of localized weekday options. */
  const weekdaysOptions = useMemo(
    () => weekdays.map((day, i) => ({ value: i + 1, label: day })),
    [weekdays]
  );

  const padTimeWithZeroes = useCallback(
    (time: number) => padStart(time.toString(), 2, '0'),
    []
  );

  const formatTimeAsHHMM = useCallback(
    (time: string, useTimezone: boolean) => {
      const parsedTime = parse(
        time,
        useTimezone ? postgresTimeFormat : postgresTimeFormatNoTimezone,
        0
      );
      const hrs =
        padTimeWithZeroes(parsedTime.getHours()) + t('shared-common.hour');
      const min =
        padTimeWithZeroes(parsedTime.getMinutes()) + t('shared-common.minute');
      return `${hrs} ${min}`;
    },
    [t, padTimeWithZeroes]
  );

  const formatIntervalAsHHMM = useCallback(
    ({ hours, minutes }: { hours: number; minutes: number }) => {
      const hrs = padTimeWithZeroes(hours) + t('shared-common.hour');
      const min = padTimeWithZeroes(minutes) + t('shared-common.minute');
      return `${hrs} ${min}`;
    },
    [t, padTimeWithZeroes]
  );

  const humanizeDuration = useCallback(
    (baseISODate: string, mins?: number | null) => {
      if (mins === null || mins === undefined) return null;
      const baseDate = new Date(baseISODate);

      const msDiff = differenceInMilliseconds(
        addMinutes(baseDate, mins),
        baseDate
      );

      const hours = Math.floor(msDiff / (60 * 60 * 1000));
      const minutes = Math.floor((msDiff % (60 * 60 * 1000)) / (60 * 1000));

      return (
        [
          hours > 0 ? `${hours}${t('common.time.hourInitial')}` : null,
          minutes > 0 ? `${minutes}${t('common.time.minuteInitial')}` : null,
        ]
          .filter(Boolean)
          .join(' ') || null
      );
    },
    [t]
  );

  return useMemo(
    () => ({
      weekdaysOptions,
      locale,
      postgresTimeFormat,
      postgresTimeFormatNoTimezone,
      getMaxDateAsISOString,
      formatTimeAsHHMM,
      padTimeWithZeroes,
      formatIntervalAsHHMM,
      humanizeDuration,
      // Localized wrappers for formatters
      format: formatWithLocale,
      formatDistance: formatDistanceWithLocale,
      formatDuration: formatDurationWithLocale,
      formatRelative: formatRelativeWithLocale,
      formatDistanceToNow: formatDistanceToNowWithLocale,
      formatDistanceStrict: formatDistanceStrictWithLocale,
      formatDistanceToNowStrict: formatDistanceToNowStrictWithLocale,
    }),
    [
      formatIntervalAsHHMM,
      humanizeDuration,
      formatTimeAsHHMM,
      weekdaysOptions,
      locale,
      padTimeWithZeroes,
      formatWithLocale,
      formatDistanceWithLocale,
      formatDurationWithLocale,
      formatRelativeWithLocale,
      formatDistanceToNowWithLocale,
      formatDistanceStrictWithLocale,
      formatDistanceToNowStrictWithLocale,
    ]
  );
};

export { useDateFns };
