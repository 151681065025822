import { useAuth } from '@/contexts/Global/AuthContext';
import { useCompany } from '@/hooks/auth/useCompany';
import { useUserProfile } from '@/hooks/auth/useUserProfile';
import { routesList } from '@/routes/routesList';

const useNavbarRoutesList = () => {
  const { companyId } = useAuth();
  const { data: company } = useCompany();
  const { hasPermissions } = useAuth();
  const { data: profile } = useUserProfile();

  return routesList.filter(
    ({
      showInNavbar,
      routeKey,
      allowSuperuser,
      allowSuperuserIfCompany,
      allowedRoles,
    }) => {
      const hasRole = hasPermissions(allowedRoles);
      const hasSuperUserAccess = allowSuperuser && profile?.isSuperuser;
      const hasSuperUserAccessIfCompany =
        allowSuperuserIfCompany && profile?.isSuperuser && !!companyId;

      if (!hasRole && !hasSuperUserAccess && !hasSuperUserAccessIfCompany) {
        return false;
      }

      switch (routeKey) {
        case 'companyProjects':
          return !!company?.featureEnableProjects;
        case 'companyGroups':
          return !!company?.featureEnableGroups;
        default:
          return showInNavbar;
      }
    }
  );
};

export { useNavbarRoutesList };
