import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus } from 'lucide-react';

import DashboardLayout from '@/components/Layouts/DashboardLayout';
import CompanyProjectsTable from './CompanyProjectsTable';
import CompanyProjectFormDialog from './CompanyProjectFormDialog';
import TKPageAppBar from '@/components/Layouts/DashboardLayout/TKPageAppBar';
import { Button } from '@/components/ui/button';

function CompanyProjectsContainer() {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <DashboardLayout
        AppBarComponent={
          <TKPageAppBar title={t('company.projects.title')}>
            <Button
              onClick={() => setCreateDialogOpen(true)}
              data-testid="open-create-project-dialog"
            >
              <Plus className="mr-2 h-4 w-4" />
              {t('company.projects.addNewProject')}
            </Button>
          </TKPageAppBar>
        }
        noScroll
      >
        <CompanyProjectsTable />
      </DashboardLayout>
      <CompanyProjectFormDialog
        dialogType="create"
        open={createDialogOpen}
        setOpen={setCreateDialogOpen}
      />
    </>
  );
}
export default CompanyProjectsContainer;
