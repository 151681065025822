import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command';
import { CheckIcon, ChevronDown, GitCompareArrowsIcon } from 'lucide-react';
import { useRef } from 'react';
import { CommandList } from 'cmdk';
import { trpc, TRPCClientErrorLike } from '@/api/trpc';
import { Spinner } from '../ui/spinner';
import { CollapsedBadges } from '../Badges/CollapsedBadges';
import { Separator } from '../ui/separator';
import TKTypedLink from '../Common/TKTypedLink';
import { Path, PathParams } from '@/routes/routesList';
import { useAuth } from '@/contexts/Global/AuthContext';
import { Badge } from '../ui/badge';

type Option<T extends string | number> = {
  id: T;
  name: string;
  badge?: string;
  disabled?: boolean;
};

interface ValueProps<T extends string | number> {
  values: T[];
  className?: string;
  disabled?: boolean;
  onChange: (values: T[]) => void;
  collapseAt?: number;
}

interface Props<T extends string | number> extends ValueProps<T> {
  options: Option<T>[];
  isLoading: boolean;
  error: Error | TRPCClientErrorLike | null;
  footer?: JSX.Element | null;
  onOpenAutoFocus?: (event: Event) => void;
}

interface SelectManageButtonProps<P extends Path> {
  children: string;
  to: P;
  params: PathParams<P>;
}

function SelectManageButton<P extends Path>({
  children,
  to,
  params,
}: SelectManageButtonProps<P>): JSX.Element {
  return (
    <>
      <Separator />
      <div className="flex justify-center w-full p-1">
        <Button
          asChild
          tabIndex={-1}
          variant="link"
          className="items-center gap-x-1 text-muted-foreground w-full"
        >
          <TKTypedLink
            to={to}
            params={params}
            target="_blank"
            className="flex items-center gap-x-1 text-muted-foreground"
          >
            <GitCompareArrowsIcon className="size-4" />
            {children}
          </TKTypedLink>
        </Button>
      </div>
    </>
  );
}

function SelectMultipleBase<T extends string | number>({
  values,
  onChange,
  options,
  isLoading,
  error,
  footer,
  disabled,
  className,
  onOpenAutoFocus,
  collapseAt,
}: Props<T>) {
  const { t } = useTranslation();

  // Filter options: include all options, mark disabled ones, and sort
  const filteredOptions = options
    .map((opt) => ({
      ...opt,
      disabled: opt.disabled || disabled,
    }))
    .sort((a, b) => {
      if (a.disabled === b.disabled) return 0;
      return a.disabled ? 1 : -1;
    });

  const selectedOptions = filteredOptions.filter((opt) =>
    values.includes(opt.id)
  );

  // Workaround: ref to the container element, necessary to fix scrolling of the popover inside dialogs
  // https://github.com/radix-ui/primitives/issues/1159#issuecomment-1741282769
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef} className={cn('w-full', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            type="button"
            variant="outline"
            role="combobox"
            className={cn(
              'h-9 w-full justify-between px-2 overflow-hidden',
              !values && 'text-muted-foreground'
            )}
            data-testid="select-multiple-trigger"
            disabled={disabled}
          >
            <CollapsedBadges
              variant="secondary"
              items={selectedOptions.map((opt) => opt.name)}
              collapseAt={collapseAt}
            />
            <ChevronDown className="ml-auto h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          container={containerRef.current}
          className="p-0"
          onOpenAutoFocus={onOpenAutoFocus}
        >
          <Command
            loop
            className="overflow-hidden"
            filter={(value: string, search: string) => {
              // keep select toggle at all times
              if (value === 'select-all') {
                return 1;
              }
              const searchKey =
                options.find((opti) => opti.id.toString() === value)?.name ??
                value;
              return searchKey.toLowerCase().includes(search.toLowerCase())
                ? 1
                : 0;
            }}
          >
            <CommandInput placeholder={t('common.search')} className="h-9" />
            <CommandList className="max-h-96 overflow-auto">
              <CommandEmpty>{t('common.no_results')}</CommandEmpty>
              {isLoading && (
                <CommandGroup>
                  <div className="flex h-full w-full items-center justify-center">
                    <Spinner size="sm" />
                  </div>
                </CommandGroup>
              )}
              {error && (
                <CommandGroup>
                  <div className="flex h-full w-full items-center justify-center">
                    <span className="text-destructive">{error.message}</span>
                  </div>
                </CommandGroup>
              )}
              {filteredOptions.length > 0 && (
                <CommandItem
                  key="select-all"
                  value="select-all"
                  className="justify-start p-2 text-muted-foreground"
                  data-testid="select-multiple-select-all-toggle"
                  onSelect={() => {
                    if (values.length === filteredOptions.length) {
                      onChange([]);
                    } else {
                      onChange(filteredOptions.map((opt) => opt.id));
                    }
                  }}
                >
                  {values.length === filteredOptions.length
                    ? t('common.deselect_all')
                    : t('common.select_all')}
                </CommandItem>
              )}
              {filteredOptions.map((option) => (
                <CommandItem
                  value={
                    option.badge
                      ? `${option.name} ${option.badge}`
                      : option.name
                  }
                  key={option.id.toString()}
                  onSelect={() => {
                    if (!option.disabled) {
                      const newValues = values.includes(option.id)
                        ? values.filter((id) => id !== option.id)
                        : [...values, option.id];
                      onChange(newValues);
                    }
                  }}
                  disabled={option.disabled}
                >
                  <div className="flex justify-between items-center w-full">
                    <span className="flex items-center gap-x-2">
                      <CheckIcon
                        className={cn(
                          'ml-auto h-4 w-4',
                          values.includes(option.id)
                            ? 'opacity-100'
                            : 'opacity-0'
                        )}
                      />
                      <span className="truncate">{option.name}</span>
                    </span>

                    {option.badge && (
                      <div className="w-28 truncate">
                        <Badge
                          variant="secondary"
                          className="w-28 block max-w-28 text-xs truncate"
                        >
                          {option.badge}
                        </Badge>
                      </div>
                    )}
                  </div>
                </CommandItem>
              ))}
            </CommandList>
            {footer}
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export function SelectMultipleGroups({
  values,
  onChange,
  disabled,
  className,
  collapseAt,
}: ValueProps<string>) {
  const { t } = useTranslation();
  const { companyId } = useAuth();
  const {
    data = [],
    isLoading,
    error,
  } = trpc.group.list.useQuery({ companyId }, { initialData: [] });

  // Only use options that exist in the fetched data
  const allOptions = data.map((dept) => ({ id: dept.id, name: dept.name }));

  // Filter values to only include those that exist in allOptions
  const validValues = values.filter((val) =>
    allOptions.some((opt) => opt.id === val.toString())
  );

  return (
    <SelectMultipleBase<string>
      className={className}
      disabled={disabled}
      options={allOptions}
      isLoading={isLoading}
      error={error}
      values={validValues.map(String)}
      onChange={onChange}
      collapseAt={collapseAt}
      footer={
        <SelectManageButton to="/groups" params={{}}>
          {t('company.groups.manage')}
        </SelectManageButton>
      }
    />
  );
}

export function SelectMultipleEquipment({
  values,
  onChange,
  disabled,
  includeSold,
  className,
  collapseAt,
}: ValueProps<string> & {
  includeSold?: string;
}) {
  const { t } = useTranslation();
  const { companyId } = useAuth();
  const {
    data = [],
    isLoading,
    error,
  } = trpc.equipment.list.useQuery(
    { companyId, includeSold },
    { initialData: [] }
  );
  // Only use options that exist in the fetched data
  const allOptions = data.map((eq) => ({ id: eq.id, name: eq.displayName }));

  // Filter values to only include those that exist in allOptions
  const validValues = values.filter((val) =>
    allOptions.some((opt) => opt.id === val.toString())
  );
  return (
    <SelectMultipleBase<string>
      className={className}
      disabled={disabled}
      options={allOptions}
      isLoading={isLoading}
      error={error}
      values={validValues.map(String)}
      onChange={onChange}
      collapseAt={collapseAt}
      footer={
        <SelectManageButton to="/equipment" params={{}}>
          {t('equipment.manage')}
        </SelectManageButton>
      }
    />
  );
}

export function SelectMultipleCompanyUsers({
  values,
  onChange,
  disabled,
  className,
  collapseAt,
  excludeCompanyUserId,
}: ValueProps<number> & {
  excludeCompanyUserId?: number;
}) {
  const { t } = useTranslation();
  const { companyId } = useAuth();
  const {
    data = [],
    isLoading,
    error,
  } = trpc.companyUser.list.useQuery({ companyId }, { initialData: [] });

  // Filter company users and exclude specified user
  const allOptions = data
    .filter((user) => user.id !== excludeCompanyUserId)
    .map((user) => ({ id: user.id, name: user.fullName }));

  // Filter values to only include those that exist in allOptions
  const validValues = values.filter((val) =>
    allOptions.some((opt) => opt.id === val)
  );

  return (
    <SelectMultipleBase<number>
      className={className}
      disabled={disabled}
      options={allOptions}
      isLoading={isLoading}
      error={error}
      values={validValues}
      onChange={onChange}
      collapseAt={collapseAt}
      footer={
        <SelectManageButton to="/users" params={{}}>
          {t('company.users.manage')}
        </SelectManageButton>
      }
    />
  );
}

export function SelectMultipleUnlinkedDriverCards({
  values,
  onChange,
  disabled,
  className,
  collapseAt,
  excludeLinkedDriverId,
}: ValueProps<string> & {
  excludeLinkedDriverId?: number;
}) {
  const { companyId } = useAuth();
  const {
    data = [],
    isLoading,
    error,
  } = trpc.driver.driverCardsList.useQuery({ companyId }, { initialData: [] });

  const allOptions: Option<string>[] = data.map((card) => ({
    id: card.id,
    name: card.tachoDriverId,
    badge: card.fullname ?? undefined,
    disabled: card.driverId !== null && card.driverId !== excludeLinkedDriverId,
  }));

  return (
    <SelectMultipleBase<string>
      className={className}
      disabled={disabled}
      options={allOptions}
      isLoading={isLoading}
      error={error}
      values={values}
      onChange={onChange}
      collapseAt={collapseAt}
    />
  );
}
